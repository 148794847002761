<template>
    <SendContract v-if="send" :id="contract.id" :status="contract.status?.state" :contact="contract.contact" :files="contract.agreement?.files"/>

    <Modal v-else>
        <template v-if="isLoading">
            <Placeholder element="span" :classes="['status-label']" :width="20"/>
            <h1><Placeholder :width="70"/></h1>
            <Placeholder element="time" :width="35"/>
            <p>
                <small>Virksomhed</small><br>
                <strong>
                    <Placeholder :width="30"/>
                </strong>
            </p>
            <Placeholder element="div" :height="51" :border-radius="15" style="margin: 2.2rem 0 0;"/>
            <Placeholder element="div" :height="51" :border-radius="15" style="margin: 2.2rem 0;"/>
        </template>
        <template v-else>
            <span :class="`status-label ${statusColor}`" :data-tooltip="formatDateToFnsDate(contract.status?.date.toDate())">{{ statusText }}</span>
            <h1>{{ contract.company.name }}</h1>
            <time>{{ formatDateToFnsDate(contract.created.toDate()) }}</time>
            <p>{{ contract.agreement.description }}</p>
            <p class="columns">
                <span>
                    <small>Virksomhed</small><br>
                    <strong>{{ contract.company.name }}</strong>
                </span>
                <span style="text-align: left">
                    <small>Adresse</small><br>
                    {{ contract.company.address }},<br>{{ contract.company.zipcode }} {{ contract.company.city }}
                </span>
            </p>
            <p class="columns">
                <span>
                    <small>Kontaktperson</small><br>
                    <strong>{{ contract.contact.name }}</strong>
                </span>
                <span style="text-align: left">
                    <small>Kontakt</small><br>
                    {{ contract.contact.email }}<br>{{ contract.contact.phone }}
                </span>
            </p>
            <p class="columns">
                <span>
                    <small>Entreprise</small><br>
                    <strong>{{ contract.agreement.subcontract }}</strong>
                </span>
                <span style="text-align: left">
                    <small>{{ getPaymentTypeLabel(contract.agreement.payment.type) }}</small><br>
                    <strong class="larger">{{ formatNumberToPrice(contract.agreement.payment.price) }}</strong>
                </span>
            </p>
            <p class="columns" v-if="contract.agreement.addition">
                <span class="align-left">
                    <small>Tillæg/fradrag i alt</small><br>
                    <strong>+ {{ formatNumberToPrice(contract.agreement.addition) }}</strong>
                </span>
            </p>
            <h2>Bilag</h2>
            <ViewFile v-if="contract.agreement.files.offer" v-bind="contract.agreement.files.offer"/>
            <ViewFile v-if="contract.agreement.files.appendix" v-bind="contract.agreement.files.appendix"/>
            <ViewFile v-if="contract.agreement.files.standard" v-bind="contract.agreement.files.standard"/>
        </template>

        <template v-if="isLoadingMails">
            <h2>Historik</h2>
            <p class="columns">
                <span class="longer">
                    <small>Status</small><br>
                    <Placeholder :width="50"/>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    <Placeholder :width="80"/>
                </span>
            </p>
            <p class="columns">
                <span class="longer">
                    <small>Status</small><br>
                    <Placeholder :width="50"/>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    <Placeholder :width="80"/>
                </span>
            </p>
        </template>

        <template v-if="!isLoadingMails && mails.length">
            <h2>Historik</h2>
            <template v-for="mail in mails" :key="mail.id">
                <p class="columns" v-if="mail.to !== userEmail">
                    <span>
                        <small>Status</small><br>
                        {{ getContractStatusObject(mail.status).label }}
                    </span>
                    <span class="grey">
                        <small>Tidspunkt</small><br>
                        {{ formatDateToFnsDate(mail.delivery.endTime.toDate(), "d. LLLL yyyy 'kl.' HH.mm") }}
                    </span>
                </p>
            </template>
        </template>

        <template v-if="!isLoadingAdditions && additions.length">
            <h2>Tillægskontrakter</h2>
            <CardList>
                <AdditionCard v-for="addition in additions" :key="addition.id" :contract="contract.id" v-bind="addition"/>
            </CardList>
        </template>

        <template #footer>
            <template v-if="contract.status?.state !== 'ACCEPTED'">
                <template v-if="!contract.status || contract.status?.state === 'REJECTED'">
                    <ActionButton label="Rediger" icon="edit" :small="true" :secondary="true" @click="edit" :disabled="!subscription.isActive" :tooltip="editTooltip"/>
                    <ActionButton label="Slet" icon="trash" :small="true" :secondary="true" :red="true" @click="isConfirmRemoveDialogOpen = true" :disabled="!subscription.isActive" :tooltip="deleteTooltip"/>
                    <ActionButton label="Send kontrakt" icon="paperplane" :small="true" @click="showSendContractPage" :disabled="!subscription.isActive" :tooltip="sendTooltip"/>
                </template>
                <template v-if="contract.status?.state === 'SENT'">
                    <ActionButton label="Send påmindelse" icon="paperplane" :small="true" :secondary="true" @click="showSendContractPage" :disabled="!subscription.isActive" :tooltip="sendTooltip"/>
                </template>
            </template>
            <template v-if="contract.status?.state === 'ACCEPTED'">
                <ActionButton label="Tillæg/fradrag til kontrakt" icon="edit" :small="true" :secondary="true"
                              :disabled="!subscription.isActive" :tooltip="addAdditionTooltip"
                              @click="$router.push({ name: 'create-addition', params: { contract: contract.id } })"/>
            </template>
        </template>

        <Dialog v-if="isConfirmRemoveDialogOpen">
            <template #title>Slet kontrakt</template>
            <template #default>
                <p>Du er ved at slette en kontrakt. Når først en kontrakt er slettet, kan den ikke genskabes.</p>
                <p v-if="contract.budget">Denne kontrakt er også tilknyttet en budgetpost, som ligeledes vil blive slettet.</p>
                <p>Er du sikker på at vil slette den alligevel?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Slet alligevel" :small="true" :red="true" @click="remove"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmRemoveDialogOpen = false"/>
            </template>
        </Dialog>
    </Modal>

</template>

<script>
import Modal from '@/components/Modal.vue';
import { useRoute, useRouter } from 'vue-router';
import { onUnmounted, reactive, toRefs } from 'vue';
import methods from '@/utils/methods';
import ViewFile from '@/components/ViewFile.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';
import { additionsCollection, contractsCollection, mailsCollection } from '@/utils/collections';
import SendContract from '@/views/view/SendContract.vue';
import Placeholder from '@/components/Placeholder.vue';
import Dialog from '@/components/Dialog.vue';
import { auth } from '@/utils/firebase';
import CardList from '@/components/CardList.vue';
import AdditionCard from '@/components/cards/AdditionCard.vue';

export default {
    name: 'Contract',
    components: {
        Modal,
        ViewFile,
        ActionButton,
        SendContract,
        Placeholder,
        Dialog,
        CardList,
        AdditionCard,
    },
    setup() {
        const { modalData, subscription } = useState();
        const hasLocalData = Object.entries(modalData.value).length;

        const router = useRouter();
        if (!hasLocalData) router.replace({ query: null });

        const route = useRoute();
        const data = reactive({
            contract: {
                id: route.params.id,
                ...modalData.value,
            },
            isLoading: !hasLocalData,
            mails: [],
            isLoadingMails: true,
            additions: [],
            isLoadingAdditions: true,
            send: route.query.action === 'send' && hasLocalData,
        });

        if (data.isLoading) {
            contractsCollection().doc(data.contract.id).get().then((snapshot) => {
                data.contract = { id: snapshot.id, ...snapshot.data() };
                data.isLoading = false;
            });
        }

        const mailUnsubscribe = mailsCollection(data.contract.id).orderBy('delivery.endTime', 'desc').onSnapshot((querySnapshot) => {
            data.mails = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));
            data.isLoadingMails = false;
        }, (err) => {
            console.log(err);
        });

        const additionUnsubscribe = additionsCollection(data.contract.id).orderBy('created', 'asc').onSnapshot((querySnapshot) => {
            data.additions = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));
            data.isLoadingAdditions = false;
        }, (err) => {
            console.log(err);
        });

        onUnmounted(() => {
            mailUnsubscribe();
            additionUnsubscribe();
        });

        return { ...toRefs(data), subscription };
    },
    data() {
        return {
            isConfirmRemoveDialogOpen: false,
            userEmail: auth.currentUser.email,
        };
    },
    watch: {
        $route(path) {
            this.send = path.query.action === 'send';
        },
    },
    mixins: [methods],
    computed: {
        statusText() {
            return this.getContractStatusObject(this.contract.status?.state).label;
        },
        statusColor() {
            return this.getContractStatusObject(this.contract.status?.state).color;
        },
        editTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at rediger kontrakt' : null;
        },
        deleteTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at slette kontrakt' : null;
        },
        sendTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at sende kontrakt' : null;
        },
        addAdditionTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at kunne oprette tillæg til kontrakt' : null;
        },
    },
    methods: {
        showSendContractPage() {
            this.$router.push({ query: { action: 'send' } });
        },
        edit() {
            this.$router.push({ name: 'edit-contract' });
        },
        async remove() {
            this.isConfirmRemoveDialogOpen = false;
            try {
                await contractsCollection().doc(this.contract.id).delete();
                await this.$router.push({ name: 'contracts' });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
